.slider1 {
    /*background: white;*/
    height: 22%;
    margin: auto;
    margin-top: 50px!important;
    margin-bottom: 50px!important;
    overflow: hidden;
    position: relative;
    width: 1140px;
}

.slider1::before, .slider1::after {
    background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 2;
}

.slider1::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider1::before {
    left: 0;
    top: 0;
}

.slider1 .slide-track1 {
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(250px * 35);

}

.slider1 .slide-track1:hover {
    animation-play-state: paused;
}

.slider1 .slide1 {
    /*height: 250px;*/
    /*width: 250px;*/
    width: 3%;
    height: auto;
    padding-right: 10px;
}

#1 {
    padding-left: 0!important;
}

#3 {
    padding-right: 0!important;
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 5));
    }
}